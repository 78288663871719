import React from "react";
import emailjs from '@emailjs/browser';
import { useState } from "react";
import Popup from "../Popup/Popup";
import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-calendar/dist/Calendar.css';
import "./Contact.css";
import { useEffect } from "react";


const Contact = (props) => {
    const {title, subtitle, image, card_bg_color, image_bg_color, background, color} = props.contact;
    const newCalendar = [];

    const [dateValue, onChange] = useState(new Date());
    const [setTime, onChangeTime] = useState(['09:00']);
    const [status, setStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState("");

    const getCalendar = localStorage.getItem("calendar");
    if(getCalendar){
        const splitCalendar = getCalendar.split('#');
        splitCalendar.map(item => newCalendar.push(new Date(item)));
    }


    const contactStyle = {
        background: background,
        color: color
    };

    const cardStyle = {
        background: card_bg_color
    };

    const imageWrapStyle = {
        background: image_bg_color
    };

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handlePhone = (event) => {
        setPhone(event.target.value);
    };
    
    const handleAddress = (event) => {
        setAddress(event.target.value);
    };

    const handleMessage = (event) => {
        setMessage(event.target.value);
    };

    useEffect(() => {
        if(status){ 
            setTimeout(() => {
                setStatus(false)
            }, 4000);
        }
    },[status])

    const sendEmail = (event) => {
        if(!event) return;
        event.preventDefault();
        let myCalendar = localStorage.getItem("calendar");
        setIsLoading(true);
        if(myCalendar){
            let newClanedarItem = myCalendar + '#' + dateValue;
            localStorage.setItem("calendar", newClanedarItem);
        } else {
            localStorage.setItem("calendar", dateValue);
        }
        emailjs.send('service_b8xuuce','template_p5knu8m', {name, email, phone, address, date: dateValue, time: setTime, message}, 'Bnso2mLVKW9JTSsFk')
        .then((response) => {
            setStatus(true);
            setIsLoading(false);
            window.location.reload();
        }, (err) => {
            console.log('FAILED...', err);
        });
    }

    return (
        <section id="contact-us" className="cx-contact" style={contactStyle}>
            <div className="cx-contact__wrap cx-page-width" style={imageWrapStyle}>
                <div className="cx-contact__image-wrap">
                    <img src={image} className="cx-contact__image" alt="cleanxeffect"/>
                </div>
                <div className="cx-contact__form-wrap" style={cardStyle}>
                    <form onSubmit={sendEmail}>
                        <h2 className="cx-contact__title">{title}</h2>
                        <h5 className="cx-contact__subtitle">{subtitle}</h5>
                        <div className="cx-filed-wrap">
                            <label htmlFor="name"></label>
                            <input type="text" id="name" name="name" value={name} onChange={handleName} placeholder="Your Name" required/>
                        </div>
                        <div className="cx-filed-wrap">
                            <label htmlFor="email"></label>
                            <input type="email" id="email" name="email" value={email} onChange={handleEmail} placeholder="Your Email" required/>
                        </div>
                        <div className="cx-filed-wrap">
                            <label htmlFor="phone"></label>
                            <input type="phone" id="phone" name="phone" value={phone} onChange={handlePhone} placeholder="Your Phone Number" required/>
                        </div>
                        <div className="cx-filed-wrap">
                            <label htmlFor="address"></label>
                            <input type="text" id="address" name="address" value={address} onChange={handleAddress} placeholder="Your Address" required/>
                        </div>
                        <div className="cx-filed-wrap">
                            <label></label>
                            <Calendar minDate={new Date()} tileDisabled={({date, view}) => (view === 'month') && newCalendar.some(item => date.getFullYear() === item.getFullYear() && date.getMonth() === item.getMonth() && date.getDate() === item.getDate())} onChange={onChange} value={dateValue}/>
                        </div>
                        <div className="cx-filed-wrap">
                            <label></label>
                            <TimePicker onChange={onChangeTime} value={setTime}/>
                        </div>
                        <div className="cx-filed-wrap">
                            <label htmlFor="message"></label>
                            <textarea id="message" name="message" placeholder="Your Message" onChange={handleMessage} value={message}></textarea>
                        </div>
                        <div className="cx-button__wrap">
                            <button type="submit" className="cx-button cx-button__pink">{ isLoading ? "..." : "Send" }</button>
                        </div>
                    </form>
                </div>
            </div>
            { status &&
                <Popup title="Thank You!" message="Our Team will get back to you within 12 hours." bgColor="#DDA8A8" color="#FFFFFF" />
            }
        </section>
    );
};

export default Contact;