import React from "react";
import "./Servicebanner.css";


const Servicebanner = (props) => {

    const {background_image, overline_color, title, subtitle, cardBgColor, color, content} = props.service;

    const overlineStyle = {
        background: overline_color
    };

    const cardStyle = {
        background: cardBgColor,
        color: color
    };

    const CompImage = () => {
        return background_image ? <img src={background_image} className="cx-servicebanner__image" alt={title}/> : null;
    }; 

    return (
        <section className="cx-servicebanner">
            <div className="cx-servicebanner__overlay" style={overlineStyle}></div>
            <div className="cx-servicebanner__image">
                {CompImage()}
            </div>
            <div className="cx-servicebanner__wrap" style={cardStyle}>
                <div className="cx-servicebanner__content-wrap">
                    <h2 className="cx-servicebanner__content-title">{title}</h2>
                    <h5 className="cx-servicebanner__content-subtitle">{subtitle}</h5>
                    <div className="cx-servicebanner__content-text" dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>
        </section>
    );
};

export default Servicebanner;
