import React from "react";
import './Popup.css';

const Popup = (props) => {
    
    const {title, message, bgColor, color} = props;
    const popupStyle = {
        background: bgColor,
        color: color
    };

    return (
        <div className="cx-popup">
            <div className="cx-popup__wrapper" style={popupStyle}>
                { title && (<h2>{title}</h2>)}
                { message && (<p>{message}</p>)}
            </div>
        </div>
    )
};

export default Popup;