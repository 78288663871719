import React from "react";
import { useState, useEffect } from "react";
import emailjs from '@emailjs/browser';
import './JoinOurTeam.css';
import Popup from "../components/Popup/Popup";
import serialize from "form-serialize";

const JoinOurTeam = () => {

    const [status, setStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(status){ 
            setTimeout(() => {
                setStatus(false)
                window.location.reload();
            }, 4000);
        }
    },[status])

    const joinOurTeamFun = (e) => {
        e.preventDefault();
        let form = serialize(e.target, { hash: true });
        setIsLoading(true);
        emailjs.send('service_b8xuuce','template_yup339s', form, 'Bnso2mLVKW9JTSsFk')
        .then((response) => {
            setStatus(true);
            setIsLoading(false);
        }, (err) => {
            console.log('FAILED...', err);
        });
    }

    return (
       <section className="cx-join">
            <div className="cx-join__wrap cx-page-width">
                <h1 className="cx-join__heading">Application for Employment</h1>
                <form onSubmit={joinOurTeamFun}>
                    <p className="cx-join__text">
                    Cleanx inc. is committed to a policy of Equal Employment Opportunity and will not
                    discriminate on any legally recognized basis, including, but not limited to, race, age,
                    color, religion, sex, marital status, national origin, citizenship, ancestry, physical or
                    mental disability, veteran status, or any other legally protected basis. 
                    </p>
                    <h2 className="cx-join__title">PERSONAL BACKGROUND</h2>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="first_name" placeholder="First Name" required/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="last_name" placeholder="Last Name" required/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="email" name="email" placeholder="Email" required/>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="present_address" placeholder="Present Address"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="present_city" placeholder="City"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="present_state" placeholder="State"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="present_zip" placeholder="Zip"/>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="permanent_address" placeholder="Permanent Address"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="permanent_city" placeholder="City"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="permanent_state" placeholder="State"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-4">
                            <input type="text" name="permanent_zip" placeholder="Zip"/>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="phone" name="telephone_number" placeholder="Telephone Number"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="phone" name="persone_contact_number" placeholder="Person to Contact in Case of Emergency"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="phone" name="emergency_phone" placeholder="Emergency Phone"/>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="number" name="social_security_number" placeholder="Social Security Number"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="number" name="driver_License_number" placeholder="Driver License Number"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="driver_state" placeholder="State"/>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-join__radio-container cx-row-2">
                            <label className="cx-join__ques">Driving is a requirement of the job. Is your license valid?</label>
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dlv_yes" name="driving_license_valid" value="yes" required/>
                                    <label htmlFor="dlv_yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dlv_no" name="driving_license_valid" value="no"/>
                                    <label htmlFor="dlv_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="cx-join__input-wrap cx-join__radio-container cx-row-2">
                            <label className="cx-join__ques">Do you have full time access to a vehicle?</label>
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dfa_yes" name="full_time_vehicle" value="yes" required/>
                                    <label htmlFor="dfa_yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dfa_no" name="full_time_vehicle" value="no"/>
                                    <label htmlFor="dfa_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="cx-join__input-wrap cx-join__radio-container cx-row-2">
                            <label className="cx-join__ques">Is your vehicle is a good and safe condition?</label>
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dgs_yes" name="good_safe_vehicle" value="yes" required/>
                                    <label htmlFor="dgs_yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dgs_no" name="good_safe_vehicle" value="no"/>
                                    <label htmlFor="dgs_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="cx-join__input-wrap cx-join__radio-container cx-row-2">
                            <label className="cx-join__ques">Is the vehicle covered by comprehensive liability insurance? What company?</label>
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dci_yes" name="insurance_vehicle" value="yes" required/>
                                    <label htmlFor="dci_yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="dci_no" name="insurance_vehicle" value="no"/>
                                    <label htmlFor="dci_no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="vehicle_make" placeholder="Make of vehicle"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="vehicle_model" placeholder="Model"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="number" name="vehicle_year" placeholder="Year"/>
                        </div>
                    </div>
                    <div className="cx-join__input-wrap cx-join__radio-container cx-row-1">
                        <label className="cx-join__ques">Are you able, at the time of employment, to Submit verification of your legal right to work In the U.S?</label>
                        <div className="cx-join__radio-group">
                            <div className="cx-join__radio-wrap">
                                <input type="radio" id="v_yes" name="verification" value="yes" required/>
                                <label htmlFor="v_yes">Yes</label>
                            </div>
                            <div className="cx-join__radio-wrap">
                                <input type="radio" id="v_no" name="verification" value="no"/>
                                <label htmlFor="v_no">No</label>
                            </div>
                        </div>
                    </div>
                    <h2 className="cx-join__title">Cleanx Inc. Application for Employment - Continued</h2>
                    <div className="cx-join__input-wrap cx-join__radio-container cx-row-1">
                        <label className="cx-join__ques">Would you have difficulty standing, bending, or kneeling In connection with performing necessary cleaning duties?</label>
                        <div className="cx-join__radio-group">
                            <div className="cx-join__radio-wrap">
                                <input type="radio" id="d_yes" name="difficulty" value="yes" required/>
                                <label htmlFor="v_yes">Yes</label>
                            </div>
                            <div className="cx-join__radio-wrap">
                                <input type="radio" id="d_no" name="difficulty" value="no"/>
                                <label htmlFor="v_no">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <label className="cx-join__label-ques">What hours are you available to work on the following week days?</label>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="mon">MON</label>
                            <input type="time" id="mon" name="mon_start"/>
                            <input type="time" name="mon_end"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="tue">TUE</label>
                            <input type="time" id="tue" name="tue_start"/>
                            <input type="time" name="tue_end"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="wed">WED</label>
                            <input type="time" id="wed" name="wed_start"/>
                            <input type="time" name="wed_end"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="thur">THUR</label>
                            <input type="time" id="thur" name="thur_start"/>
                            <input type="time" name="thur_end"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="fri">FRI</label>
                            <input type="time" id="fri" name="fri_start"/>
                            <input type="time" name="fri_end"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="sat">SAT</label>
                            <input type="time" id="sat" name="sat_start"/>
                            <input type="time" name="sat_end"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-7">
                            <label htmlFor="sun">SUN</label>
                            <input type="time" id="sun" name="sun_start"/>
                            <input type="time" name="sun_end"/>
                        </div>
                    </div>
                    <div className="cx-join__input-group">
                        <div className="cx-join__input-wrap cx-row-3">
                            <label htmlFor="when_can_start">If you are hired, when can you start work?</label>
                            <textarea name="when_can_start" id="when_can_start"></textarea>
                        </div>
                        <div className="cx-join__input-wrap cx-join__radio-container cx-row-3">
                            <label className="cx-join__ques">Are you presently employed?</label>
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="pe_yes" name="employed" value="yes" required/>
                                    <label htmlFor="pe_yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="pe_no" name="employed" value="no"/>
                                    <label htmlFor="pe_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="cx-join__input-wrap cx-join__radio-container cx-row-3">
                            <label className="cx-join__ques">If yes, may we contact your present employer?</label>
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="cpe_yes" name="present_employer" value="yes" required/>
                                    <label htmlFor="cpe_yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="cpe_no" name="present_employer" value="no"/>
                                    <label htmlFor="cpe_no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="cx-join__input-wrap cx-join__checkbox-container cx-row-1">
                            <label className="cx-join-checkbox__ques">Which of the following categories of jobs have you had?</label>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="housecleaning" name="categories[]" value="Housecleaning"/>
                                <label htmlFor="housecleaning">Housecleaning</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="fastfood" name="categories[]" value="Fast Food"/>
                                <label htmlFor="fastfood">Fast Food</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="homemaker" name="categories[]" value="Homemaker"/>
                                <label htmlFor="homemaker">Homemaker</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="housecleaning" name="categories[]" value="Hotel/Motel"/>
                                <label htmlFor="hotelmotel">Hotel/Motel</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="janitorial" name="categories[]" value="Janitorial"/>
                                <label htmlFor="janitorial">Janitorial</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="manufacturing" name="categories[]" value="Manufacturing"/>
                                <label htmlFor="manufacturing">Manufacturing</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="restaurant" name="categories[]" value="Restaurant"/>
                                <label htmlFor="restaurant">Restaurant</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="sales" name="categories[]" value="Sales"/>
                                <label htmlFor="sales">Sales</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="service" name="categories[]" value="Service"/>
                                <label htmlFor="service">Service</label>
                            </div>
                            <div className="cx-join__checkbox-wrap cx-row-5">
                                <input type="checkbox" id="otherexplain" name="categories[]" value="Other (explain):"/>
                                <label htmlFor="otherexplain">Other (explain):</label>
                            </div>
                        </div>
                        <div className="cx-join__input-wrap cx-row-2">
                            <label htmlFor="criminal">Have you ever been convicted of a criminal offense?</label>
                            <textarea name="criminal" id="criminal"></textarea>
                        </div>
                        <div className="cx-join__input-wrap cx-row-2">
                            <label htmlFor="driversuspended">Has your driver’s license ever been suspended?</label>
                            <textarea name="driver_suspended" id="driversuspended"></textarea>
                        </div>
                    </div>
                    <h2 className="cx-join__title">Personal References</h2>
                    <div className="cx-join__input-group">
                        <label className="cx-join-personal__ques">List the names of three persons not related to you, whom you have known for at least 3 years.</label>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="list_name_1" placeholder="Name"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="list_occupation_1" placeholder="Occupation"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="phone" name="list_phone_1" placeholder="Phone"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="list_name_2" placeholder="Name"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="list_occupation_2" placeholder="Occupation"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="phone" name="list_phone_2" placeholder="Phone"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="list_name_3" placeholder="Name"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="text" name="list_occupation_3" placeholder="Occupation"/>
                        </div>
                        <div className="cx-join__input-wrap cx-row-3">
                            <input type="phone" name="list_phone_3" placeholder="Phone"/>
                        </div>
                    </div>
                    <h2 className="cx-join__title">APPLICANT'S STATEMENT</h2>
                    <h5 className="cx-join-note__title">
                        PLEASE READ THE FOLLOWING STATEMENTS CAREFULLY BEFORE
                        SIGNING THIS APPLICATION. ONLY THOSE APPLICATIONS WHICH ARE
                        SIGNED AND DATED ARE CONSIDERED VALID. IF YOU HAVE ANY
                        QUESTIONS REGARDING THIS STATEMENT, PLEASE ASK THEM BEFORE
                        SIGNING.
                    </h5>
                    <div className="cx-join__agree-wrap">
                        <div className="cx-join__agree-p">
                            In signing this application, I certify that all of the foregoing information is a
                            complete and accurate statement of the facts and understand that if any
                            misrepresentation, omission or falsification be discovered, it will constitute
                            grounds for dismissal. I hereby authorize Cleanx Inc., to which I am
                            applying, to conduct any investigation necessary concerning any part of my
                            background related to the position I am seeking, including, but not limited
                            to, the obtaining of a police report and driving record. I authorize any of the
                            persons or organizations named in this application to provide complete
                            information and records regarding my employment, education, character
                            and qualifications. I release all parties from any liability in connection with
                            the provision and use of such information.
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="agree1yes" name="agree_1" value="yes" required/>
                                    <label htmlFor="agree1yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="agree1no" name="agree_1" value="no"/>
                                    <label htmlFor="agree1no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cx-join__agree-wrap">
                        <div className="cx-join__agree-p">
                            I understand and agree that, if employed by Cleanx Inc., I will be
                            responsible for familiarizing myself with all rules and regulations of Cleanx
                            Inc. as they presently exist or are later modified and that I will abide by its
                            rules and regulations which I understand are subject to change.
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="agree2yes" name="agree_2" value="yes" required/>
                                    <label htmlFor="agree2yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="agree2no" name="agree_2" value="no"/>
                                    <label htmlFor="agree2no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cx-join__agree-wrap">
                        <div className="cx-join__agree-p">
                            I also understand that no representative of Cleanx Inc. has any authority to
                            enter into any employment agreement for any specified period of time, or
                            to assure me of any future position, benefits, or terms and conditions of
                            employment. I further understand that, if hired, my employment is for no
                            definite period of time and may be terminated by either party at any time.
                            I have read, understand and agree with the above.
                            <div className="cx-join__radio-group">
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="agree3yes" name="agree_3" value="yes" required/>
                                    <label htmlFor="agree3yes">Yes</label>
                                </div>
                                <div className="cx-join__radio-wrap">
                                    <input type="radio" id="agree3no" name="agree_3" value="no"/>
                                    <label htmlFor="agree3no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="cx-join__title">Applicant's Signature</h2>
                    <div className="cx-join__input-wrap cx-row-1">
                        <input type="date" name="date" placeholder="Date"/>
                    </div>
                    <p className="cx-join__message">
                        This application is current for only 60 days from the date above signed. At
                        the conclusion of this time, if I have not heard from the employer and still
                        wish to be considered for employment, it will be necessary to fill out a new
                        application.
                    </p>
                    <div className="cx-join__form-button-wrap">
                        <button type="submit" className="cx-button cx-button__pink">{ isLoading ? "..." : "Join Our Team"}</button>
                    </div>
                </form>
            </div>
            { status &&
                <Popup title="Thank you for joining our team!" message="Our Team will get back to you soon." bgColor="#DDA8A8" color="#FFFFFF" />
            }
       </section>
    )
};

export default JoinOurTeam;