import React from "react";
import {useParams} from "react-router-dom";
import Page from "../components/Page/Page";
import data from '../data';

const PrivacyPolicy = () => {
    const {id} = useParams();
    const pageContent = data?.pages?.filter(page => page.handle === id);
    if(!id || !pageContent.length) return window.location.href = '/404';
    return (
        <Page content={pageContent[0]} />
    )
};

export default PrivacyPolicy;