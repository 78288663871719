import React from "react";
import "./Footerbottom.css";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedin, FaPhone } from "react-icons/fa";


const Footerbottom = (props) => {
    const {
        instagram, 
        facebook, 
        linkedin, 
        twitter,
        call_text,
        phone1,
        phone1url,
        phone2,
        phone2url, 
        logo, 
        background, 
        color, 
        menu
    } = props.footerdata;

    const footerStyle = {
        background: background,
        color: color
    };

    const currentDate = new Date();

    return (
        <footer className="cx-footer" style={footerStyle}>
            <div className="cx-footer__wrap cx-page-width">
                <div className="cx-footer__items-wrap">
                    <div className="cx-footer__item">
                        <a href="/" className="cx-footer__logo">
                            <img src={logo} width="320px" height="auto" alt="Cleanxeffect"/>
                        </a>
                        <div className="cx-footer__social-wrap">
                            { facebook && <div className="cx-footer__social-item"><a target="__blank" href={facebook}><FaFacebookF /></a></div>}
                            { instagram && <div className="cx-footer__social-item"><a target="__blank" href={instagram}><FaInstagram /></a></div>}
                            { twitter && <div className="cx-footer__social-item"><a target="__blank" href={twitter}><FaTwitter /></a></div>}
                            { linkedin && <div className="cx-footer__social-item"><a target="__blank" href={linkedin}><FaLinkedin /></a></div>}
                        </div>
                        <div className="cx-footer__phone-wrap">
                            {call_text && (
                                <h2>{call_text}</h2>
                            )}
                            {phone1 && (
                                <a href={phone1url}>
                                    <FaPhone />
                                    {phone1}
                                </a>
                            )}
                            {phone2 && (
                                <a href={phone2url}>
                                    <FaPhone />
                                    {phone2}
                                </a>
                            )}
                        </div>
                    </div>
                    {menu?.map((menuItem, index) => 
                        <div className="cx-footer__item" key={index}>
                            <h2>{menuItem.title}</h2>
                            <ul>
                                {menuItem.items.map((item,index) => 
                                    <li key={index}><a href={item?.link}>{item?.name}</a></li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="cx-footer__copy-wrap">
                    <span className="cx-footer__copy-right">© {currentDate.getFullYear()} Cleanx Inc.</span>
                </div>
            </div>
        </footer>
    );
};

export default Footerbottom;