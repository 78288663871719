import React from "react";
import "./Banner.css";

const Banner = ({image,title,content}) => {
    return (
        <section className="cx-banner">
            <div className="cx-banner__wrap">
                <div className="cx-banner__overline"></div>
                <div className="cx-banner__image-wrap">
                    <img className="cx-banner__image" src={image} alt="Cleanx Effect"/>
                </div>
                <div className="cx-banner__content">
                    <h1 className="cx-banner__title">{title}</h1>
                    <p className="cx-banner__text">{content}</p>
                </div>
            </div>
        </section>
    );
}

export default Banner;