import React from "react";
import { useState } from "react";
import { FaPhoneVolume, FaBars, FaWindowClose, FaAngleDown } from "react-icons/fa";
import './Navbar.css'

const Navbar = ({logo,navbar}) => {
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <nav className={isOpen ? "cx-navbar cx-navbar__open" : "cx-navbar"}>
            <div className="cx-navbar__wrap cx-page-width">
                <button className="cx__navbar-mobile-open" onClick={openMenu}>{ isOpen ? <FaWindowClose/> : <FaBars/>}</button>
                <div className="cx-navbar__logo-wrap">
                    <a href="/" className="cx-logo__link">
                        <img src={logo} height="44px" width="auto" className="cx-logo" alt="Cleanxeffect"/>
                    </a>
                </div>
                <div className="cx-navbar__menu-wrap">
                    
                    <ul className="cx-navbar__menu">
                        {navbar?.map((item,index) => 
                            <li className="cx-navbar__menu-item" key={index}>
                                <a href={item.url} className={(item.icon || item.childMenu) ? item.childMenu ? "cx-have__child" : "cx-have__icon": null}>
                                    {item.icon ? <FaPhoneVolume/> : null}
                                    {item.title}
                                    {item.childMenu ? <FaAngleDown/> : null}
                                </a>
                                { item.childMenu && item.childMenu.length && 
                                    <ul className="cx-navbar__child-menu">
                                        {item.childMenu.map((childItem, childIndex) =>
                                          <li key={childIndex}>
                                            <a href={childItem.url}>
                                                {childItem.title}
                                            </a>
                                          </li>
                                        )}
                                    </ul>   
                                }
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;