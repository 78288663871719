import './App.css';
import { Navigate, Routes, Route } from "react-router-dom";

import HomePage from './pages/HomePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import JoinOurTeam from './pages/JoinOurTeam';
import PageNotFound from './pages/404';
import Services from './pages/Services';

import Navbar from './components/Navbar/Navbar';
import Contact from './components/Contact/Contact';
import Footerbottom from './components/Footer/Footerbottom';
import data from './data';

function App() {

  return (
    <div className="App">
      <Navbar logo={data.logo} navbar={data.navbar} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/book-now" element={<Contact contact={data.contact} />} />
          <Route path='/services/:id' element={<Services services={data.services}/>} />
          <Route path="/pages/:id" element={<PrivacyPolicy />} />
          <Route path="/join-our-team" element={<JoinOurTeam />} /> 
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" exact={true} element={<Navigate replace to="/404" />} />
        </Routes>
      <Footerbottom footerdata={data.footer} />
    </div>
  );
}

export default App;
