import React from "react";
import './404.css';

const PageNotFound = () => {
    return (
        <section className="cx-404">
            <div className="cx-404__wrap cx-page-width">
                <a href="/">
                    <img src="/images/cleanxeffect.png" alt="Cleanx Inc."/>
                </a>
                <h2 className="cx-404__heading">404 Page not found</h2>
            </div>
        </section>
    )
}

export default PageNotFound;