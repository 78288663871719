import React from 'react';
import Banner from '../components/Banner/Banner';
import data from '../data';

const HomePage = () => {
    return (
        <>
            <Banner image={data.banner.image} title={data.banner.title} content={data.banner.content}/>
        </>
    )
};

export default HomePage;