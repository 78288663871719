import React from "react";
import {useParams} from "react-router-dom";
import Service from '../components/Service/Service';
import Servicebanner from '../components/Servicebanner/Servicebanner';

const Services = ({services}) => {
    const {id} = useParams();
    if(!services || !id) return window.location.href = '/404';
    const service = services.filter(service => service.handle === id);
    if(!service.length) return window.location.href = '/404';

    return(
        <>
            { service[0].type === "image-with-text" && <Service key={id} service={service[0]} />}
            { service[0].type === "image-with-text-banner" && <Servicebanner key={id} service={service[0]} />}
        </>
    )
}

export default Services;