import React from "react";
import "./Service.css";


const Service = (props) => {

    const {image, style, title, subtitle, bgColor, color, content, position} = props.service;

    const sectionStyle = {
        background: bgColor,
        color: color
    };

    const CompImage = () => {
        return image ? <img src={image} className={style === "border" ? "cx-service__image-border" : "cx-service__image"} alt={title}/> : null;
    }; 

    return (
        <section className={ style === "full-width" ?  "cx-service__full-width" : "cx-service"} style={sectionStyle}>
            <div className={`cx-service__wrap ${ style === "full-width" ? "cx-full-width" : "cx-page-width"} ${position === "right" ? "cx-service-right" : "cx-service-left"}`}>
                <div className="cx-service__image-wrap">
                    {CompImage()}
                </div>
                <div className="cx-service__content-wrap">
                    <h2 className="cx-service__content-title">{title}</h2>
                    <h5 className="cx-service__content-subtitle">{subtitle}</h5>
                    <div className="cx-service__content-text" dangerouslySetInnerHTML={{__html: content}}/>
                </div>
            </div>
        </section>
    );
};

export default Service;
