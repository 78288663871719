import React from "react";
import './Page.css';

const Page = (props) => {

    const {content} = props;

    return (
        <section className="cx-page">
            <div className="cx-page__wrapper cx-page-width">
                <h1 className="cx-page__title">{content.name}</h1>
                <ul>
                    {content?.content?.map((item, index) => 
                        <li key={index}>{item.text}</li>
                    )}
                </ul>
            </div>
        </section>
    )
};

export default Page;